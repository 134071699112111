import {
  Icon,
  Table,
  TableCellProps,
  TableColumnHeaderProps,
  TableProps,
  TableRowProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@ui/index";
import { Translate } from "next-translate";
import useTranslation from "next-translate/useTranslation";
import { ReactNode } from "react";
import { MdArrowDownward, MdArrowUpward } from "react-icons/md";

type Column = {
  Header?: ReactNode;
  Cell?: (data: any, t: Translate) => ReactNode;
  SortKey?: string;
  accessor?: string;
};

type Props = {
  columns: Column[];
  data: any[];
  RowProps?: TableRowProps;
  HeaderProps?: TableColumnHeaderProps;
  CellProps?: TableCellProps;
  RowCallback?: any;
  RowAction?: JSX.Element | any;
  RowActionTitle?: string;
  RowClick?: (x: any) => void;
  onHeaderClick?: (key: string) => void;
  sort?: { key: string; order: string };
  isSecondRow?: boolean;
  secondRow?: ReactNode;
} & Partial<TableProps>;

export const TableContent = ({
  data,
  columns,
  RowProps,
  HeaderProps,
  CellProps,
  RowCallback,
  RowAction,
  RowActionTitle,
  RowClick,
  onHeaderClick,
  sort,
  ...props
}: Props) => {
  const { t } = useTranslation();
  return (
    <Table
      style={{
        WebkitUserSelect: "text",
        KhtmlUserSelect: "text",
        MozUserSelect: "text",
        msUserSelect: "text",
      }}
      fontSize="sm"
      size="sm"
      variant="simple"
      {...props}
    >
      <Thead>
        <Tr borderColor="blue.100">
          {columns.map((column, index) => (
            <Th
              key={index}
              whiteSpace="nowrap"
              scope="col"
              {...HeaderProps}
              cursor={onHeaderClick && column.SortKey ? "pointer" : "initial"}
              color={sort?.key === column.SortKey ? "blue.500!" : "gray.500"}
              fontWeight={sort?.key === column.SortKey ? "bold!" : "initial"}
              onClick={() =>
                onHeaderClick && column.SortKey
                  ? onHeaderClick(column.SortKey)
                  : null
              }
            >
              {column.Header}
              {sort?.key === column.SortKey && (
                <Icon
                  as={sort?.order === "desc" ? MdArrowDownward : MdArrowUpward}
                  mb={-0.5}
                />
              )}
            </Th>
          ))}
          {RowAction && <Th {...HeaderProps}>{RowActionTitle}</Th>}
        </Tr>
      </Thead>
      <Tbody>
        {data &&
          data.length > 0 &&
          data.map((row, index) => (
            <>
              <Tr
                key={index}
                {...RowProps}
                onClick={() => (RowClick ? RowClick(row) : null)}
              >
                {columns.map((column, index) => {
                  const cell = column.accessor
                    ? row[column.accessor as keyof typeof row]
                    : row;
                  const element = column.Cell?.(cell, t) ?? cell;

                  return (
                    <Td
                      key={index}
                      maxW={64}
                      minW={24}
                      {...CellProps}
                      fontSize="12px"
                    >
                      {Array.isArray(element) ? "" : element}
                    </Td>
                  );
                })}
                {RowAction && (
                  <Td textAlign="right" pr="0" {...CellProps}>
                    <RowAction rowData={row} refetch={RowCallback} />
                  </Td>
                )}
              </Tr>
              {props.isSecondRow && (
                <Tr key={"0"}>
                  <Td colSpan={columns.length}>{props.secondRow}</Td>
                </Tr>
              )}
            </>
          ))}
      </Tbody>
    </Table>
  );
};
