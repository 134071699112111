import { SystemStyleObject } from "@chakra-ui/theme-tools";

const baseStyle: SystemStyleObject = {};

const sizes: Record<string, SystemStyleObject> = {};

const variants = {
  basic: {
    tr: {
      px: 0,
      borderBottom: "1px",
      borderColor: "gray.100",
      td: {
        py: "10px",
        pl: 0,
      },
      th: {
        py: 2,

        pl: 0,
        fontWeight: "500",
        fontSize: "x-small",
      },
    },
  },
  calendar: {
    tr: {
      p: 0,
      borderBottom: "0px",
      borderColor: "gray.100",
      td: {
        py: "4px",
        px: "2px",
        textAlign: "center",
      },
      th: {
        pl: 0,
        color: "gray.500",
        fontWeight: "500",
        fontSize: "x-small",
        td: {
          py: "4px",
          px: "2px",
          textAlign: "center",
        },
      },
    },
  },
  saas: {
    tr: {
      bg: "#ffffff",
      borderX: "1px",
      borderColor: "#e5e8f0",
      td: {
        borderY: "1px",
        borderColor: "#e5e8f0",
        py: "8px",
        px: "9px",
      },
      th: {
        py: "8px",
        px: "9px",
        borderY: "1px",
        borderColor: "#e5e8f0",
        color: "#21293b",

        fontWeight: "600",
        fontSize: "x-small",
      },
    },
  },
  noBottom: {
    tr: {
      px: 0,
      borderBottom: "0px",
      borderColor: "gray.100",
      td: {
        py: "8px",
        pl: 0,
      },
      th: {
        py: 2,

        pl: 0,
        color: "gray.500",
        fontWeight: "500",
        fontSize: "x-small",
      },
    },
  },
};

const defaultProps = {};

const Table = {
  variants,
};

export default Table;
