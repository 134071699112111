import { Country } from "@prisma/client";
import _ from "lodash";

export const AllowedCountryPhoneCodes = [
  { country: Country.us, code: "+1" },
  { country: Country.mn, code: "+976" },
  { country: Country.gt, code: "+502" },
  { country: Country.sv, code: "+503" },
  { country: Country.hn, code: "+504" },
  { country: Country.ph, code: "+63" },
  { country: Country.hu, code: "+36" },
  { country: Country.gu, code: "+1" },
  { country: Country.ad, code: "+376" },
  { country: Country.ae, code: "+971" },
  { country: Country.af, code: "+93" },
  { country: Country.ag, code: "+1" },
  { country: Country.ai, code: "+1" },
  { country: Country.al, code: "+355" },
  { country: Country.am, code: "+374" },
  { country: Country.ao, code: "+244" },
  { country: Country.aq, code: "+672" },
  { country: Country.ar, code: "+54" },
  { country: Country.as, code: "+1" },
  { country: Country.at, code: "+43" },
  { country: Country.au, code: "+61" },
  { country: Country.aw, code: "+297" },
  { country: Country.ax, code: "+358" },
  { country: Country.az, code: "+994" },
  { country: Country.ba, code: "+387" },
  { country: Country.bb, code: "+1" },
  { country: Country.bd, code: "+880" },
  { country: Country.be, code: "+32" },
  { country: Country.bf, code: "+226" },
  { country: Country.bg, code: "+359" },
  { country: Country.bh, code: "+973" },
  { country: Country.bi, code: "+257" },
  { country: Country.bj, code: "+229" },
  { country: Country.bl, code: "+590" },
  { country: Country.bm, code: "+1" },
  { country: Country.bn, code: "+673" },
  { country: Country.bo, code: "+591" },
  { country: Country.bq, code: "+599" },
  { country: Country.br, code: "+55" },
  { country: Country.bs, code: "+1" },
  { country: Country.bt, code: "+975" },
  { country: Country.bv, code: "+47" },
  { country: Country.bw, code: "+267" },
  { country: Country.by, code: "+375" },
  { country: Country.bz, code: "+501" },
  { country: Country.ca, code: "+1" },
  { country: Country.cd, code: "+243" },
  { country: Country.cf, code: "+236" },
  { country: Country.cg, code: "+242" },
  { country: Country.ch, code: "+41" },
  { country: Country.ci, code: "+225" },
  { country: Country.ck, code: "+682" },
  { country: Country.cl, code: "+56" },
  { country: Country.cm, code: "+237" },
  { country: Country.cn, code: "+86" },
  { country: Country.co, code: "+57" },
  { country: Country.cr, code: "+506" },
  { country: Country.cu, code: "+53" },
  { country: Country.cv, code: "+238" },
  { country: Country.cw, code: "+599" },
  { country: Country.cy, code: "+357" },
  { country: Country.cz, code: "+420" },
  { country: Country.de, code: "+49" },
  { country: Country.dj, code: "+253" },
  { country: Country.dk, code: "+45" },
  { country: Country.dm, code: "+1" },
  { country: Country.do, code: "+1" },
  { country: Country.dz, code: "+213" },
  { country: Country.ec, code: "+593" },
  { country: Country.ee, code: "+372" },
  { country: Country.eg, code: "+20" },
  { country: Country.er, code: "+291" },
  { country: Country.et, code: "+251" },
  { country: Country.fi, code: "+358" },
  { country: Country.fj, code: "+679" },
  { country: Country.fk, code: "+500" },
  { country: Country.fm, code: "+691" },
  { country: Country.fo, code: "+298" },
  { country: Country.fr, code: "+33" },
  { country: Country.ga, code: "+241" },
  { country: Country.gb, code: "+44" },
  { country: Country.gd, code: "+1" },
  { country: Country.ge, code: "+995" },
  { country: Country.gf, code: "+594" },
  { country: Country.gg, code: "+44" },
  { country: Country.gh, code: "+233" },
  { country: Country.gi, code: "+350" },
  { country: Country.gl, code: "+299" },
  { country: Country.gm, code: "+220" },
  { country: Country.gn, code: "+224" },
  { country: Country.gp, code: "+590" },
  { country: Country.gq, code: "+240" },
  { country: Country.gr, code: "+30" },
  { country: Country.gw, code: "+245" },
  { country: Country.gy, code: "+592" },
  { country: Country.hk, code: "+852" },
  { country: Country.hr, code: "+385" },
  { country: Country.ht, code: "+509" },
  { country: Country.id, code: "+62" },
  { country: Country.ie, code: "+353" },
  { country: Country.il, code: "+972" },
  { country: Country.im, code: "+44" },
  { country: Country.in, code: "+91" },
  { country: Country.io, code: "+246" },
  { country: Country.iq, code: "+964" },
  { country: Country.ir, code: "+98" },
  { country: Country.is, code: "+354" },
  { country: Country.it, code: "+39" },
  { country: Country.je, code: "+44" },
  { country: Country.jm, code: "+1" },
  { country: Country.jo, code: "+962" },
  { country: Country.jp, code: "+81" },
  { country: Country.ke, code: "+254" },
  { country: Country.kg, code: "+996" },
  { country: Country.kh, code: "+855" },
  { country: Country.ki, code: "+686" },
  { country: Country.km, code: "+269" },
  { country: Country.kn, code: "+1" },
  { country: Country.kp, code: "+850" },
  { country: Country.kr, code: "+82" },
  { country: Country.kw, code: "+965" },
  { country: Country.ky, code: "+1" },
  { country: Country.kz, code: "+7" },
  { country: Country.la, code: "+856" },
  { country: Country.lb, code: "+961" },
  { country: Country.lc, code: "+1" },
  { country: Country.li, code: "+423" },
  { country: Country.lk, code: "+94" },
  { country: Country.lr, code: "+231" },
  { country: Country.ls, code: "+266" },
  { country: Country.lt, code: "+370" },
  { country: Country.lu, code: "+352" },
  { country: Country.lv, code: "+371" },
  { country: Country.ly, code: "+218" },
  { country: Country.ma, code: "+212" },
  { country: Country.mc, code: "+377" },
  { country: Country.md, code: "+373" },
  { country: Country.me, code: "+382" },
  { country: Country.mf, code: "+590" },
  { country: Country.mg, code: "+261" },
  { country: Country.mh, code: "+692" },
  { country: Country.mk, code: "+389" },
  { country: Country.ml, code: "+223" },
  { country: Country.mm, code: "+95" },
  { country: Country.mo, code: "+853" },
  { country: Country.mp, code: "+1" },
  { country: Country.mq, code: "+596" },
  { country: Country.mr, code: "+222" },
  { country: Country.ms, code: "+1" },
  { country: Country.mt, code: "+356" },
  { country: Country.mu, code: "+230" },
  { country: Country.mv, code: "+960" },
  { country: Country.mw, code: "+265" },
  { country: Country.mx, code: "+52" },
  { country: Country.my, code: "+60" },
  { country: Country.mz, code: "+258" },
  { country: Country.na, code: "+264" },
  { country: Country.nc, code: "+687" },
  { country: Country.ne, code: "+227" },
  { country: Country.nf, code: "+672" },
  { country: Country.ng, code: "+234" },
  { country: Country.ni, code: "+505" },
  { country: Country.nl, code: "+31" },
  { country: Country.no, code: "+47" },
  { country: Country.np, code: "+977" },
  { country: Country.nr, code: "+674" },
  { country: Country.nu, code: "+683" },
  { country: Country.nz, code: "+64" },
  { country: Country.om, code: "+968" },
  { country: Country.pa, code: "+507" },
  { country: Country.pe, code: "+51" },
  { country: Country.pf, code: "+689" },
  { country: Country.pg, code: "+675" },
  { country: Country.pk, code: "+92" },
  { country: Country.pl, code: "+48" },
  { country: Country.pm, code: "+508" },
  { country: Country.pn, code: "+870" },
  { country: Country.pr, code: "+1" },
  { country: Country.ps, code: "+970" },
  { country: Country.pt, code: "+351" },
  { country: Country.pw, code: "+680" },
  { country: Country.py, code: "+595" },
  { country: Country.qa, code: "+974" },
  { country: Country.re, code: "+262" },
  { country: Country.ro, code: "+40" },
  { country: Country.rs, code: "+381" },
  { country: Country.ru, code: "+7" },
  { country: Country.rw, code: "+250" },
  { country: Country.sa, code: "+966" },
  { country: Country.sb, code: "+677" },
  { country: Country.sc, code: "+248" },
  { country: Country.sd, code: "+249" },
  { country: Country.se, code: "+46" },
  { country: Country.sg, code: "+65" },
  { country: Country.si, code: "+386" },
  { country: Country.sj, code: "+47" },
  { country: Country.sk, code: "+421" },
  { country: Country.sl, code: "+232" },
  { country: Country.sm, code: "+378" },
  { country: Country.sn, code: "+221" },
  { country: Country.so, code: "+252" },
  { country: Country.sr, code: "+597" },
  { country: Country.ss, code: "+211" },
  { country: Country.st, code: "+239" },
  { country: Country.sx, code: "+1" },
  { country: Country.sy, code: "+963" },
  { country: Country.sz, code: "+268" },
  { country: Country.tc, code: "+1" },
  { country: Country.td, code: "+235" },
  { country: Country.tf, code: "+262" },
  { country: Country.tg, code: "+228" },
  { country: Country.th, code: "+66" },
  { country: Country.tj, code: "+992" },
  { country: Country.tk, code: "+690" },
  { country: Country.tl, code: "+670" },
  { country: Country.tm, code: "+993" },
  { country: Country.tn, code: "+216" },
  { country: Country.to, code: "+676" },
  { country: Country.tr, code: "+90" },
  { country: Country.tt, code: "+1" },
  { country: Country.tv, code: "+688" },
  { country: Country.tw, code: "+886" },
  { country: Country.tz, code: "+255" },
  { country: Country.ua, code: "+380" },
  { country: Country.ug, code: "+256" },
  { country: Country.um, code: "+1" },
  { country: Country.uy, code: "+598" },
  { country: Country.uz, code: "+998" },
  { country: Country.va, code: "+39" },
  { country: Country.vc, code: "+1" },
  { country: Country.ve, code: "+58" },
  { country: Country.vg, code: "+1" },
  { country: Country.vi, code: "+1" },
  { country: Country.vn, code: "+84" },
  { country: Country.vu, code: "+678" },
  { country: Country.wf, code: "+681" },
  { country: Country.ws, code: "+685" },
  { country: Country.ye, code: "+967" },
  { country: Country.yt, code: "+262" },
  { country: Country.za, code: "+27" },
  { country: Country.zm, code: "+260" },
  { country: Country.zw, code: "+263" },
];

export const getAllowedCountryPhoneCode = () =>
  AllowedCountryPhoneCodes.map((x) => x.code);

export const getPhoneCodeByCountry = (country: Country): string =>
  AllowedCountryPhoneCodes.find((obj) => obj.country === country)?.code ?? "+1";

export const getCountryByPhoneCode = (code: string): Country | undefined =>
  AllowedCountryPhoneCodes.find((obj) => obj.code === code)?.country as Country;

export const getPhoneWithCountry = (country: Country, phone: string) =>
  getPhoneCodeByCountry(country) + phone;

export const isPhoneCodeAllowed = (code: string): boolean =>
  !!_.find(AllowedCountryPhoneCodes, (c) => c.code === code);

export const mainSupportingCountry = (country?: string) => {
  if (country == "mn" || country == "us") return true;
  // if (country == "us") return true;
  return false;
};
