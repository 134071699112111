import { createStandaloneToast, Icon, ToastPosition } from "@chakra-ui/react";
import { ReactNode } from "react";
import { IconType } from "react-icons/lib";
import { theme } from "../theme";

const position = "top";
const duration = 9000;
const isClosable = true;

const { toast } = createStandaloneToast({ theme });

export const toaster = {
  success(message: ReactNode, title = "", positionManual?: ToastPosition) {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "success",
      position: positionManual ? positionManual : position,
      duration,
      isClosable,
    });
  },
  closeAll() {
    toast.closeAll();
  },
  info(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "info",
      position,
      duration,
      isClosable,
    });
  },
  warning(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "warning",
      position,
      duration,
      isClosable,
    });
  },
  error(message: string, title = "") {
    toast.closeAll();
    toast({
      title,
      description: message,
      status: "error",
      position,
      duration,
      isClosable,
    });
  },
  nourish(message: string, icon: IconType) {
    toast.closeAll();
    toast({
      title: "",
      description: message,
      status: "warning",
      position: "bottom",
      duration,
      isClosable,
      icon: <Icon as={icon} m={1} fontSize="md" />,
      containerStyle: {
        pos: "absolute",
        bottom: 20,
        fontSize: 13,
        fontWeight: "bold",
        fontFamily: "Golos Text Variable",
      },
    });
  },
};
