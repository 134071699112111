import { useLocalStorage } from "@lib/widget/data/hooks";
import { Country } from "@prisma/client";
import posthog from "posthog-js";
import { useEffect, useState } from "react";

export const POSTHOG_TRACKING_ID =
  process.env.NEXT_PUBLIC_POSTHOG_TRACKING_ID || "";
export const POSTHOG_API_HOST =
  process.env.NEXT_PUBLIC_POSTHOG_API_HOST || "https://app.posthog.com";

const isDev = process.env.NODE_ENV === "development";

export const useAnalytics = () => {
  const [analytics, setAnalytics] = useState<{
    id: string;
    country: Country;
    paywallAB: boolean;
    getFlag: (key: string) => string | boolean | undefined;
  }>();
  const [paywallAB, setPaywallAB] = useLocalStorage("paywall-a-b", false);

  useEffect(() => {
    if (isDev) return;
    try {
      posthog.init(POSTHOG_TRACKING_ID, {
        api_host: POSTHOG_API_HOST,
        session_recording: { maskAllInputs: false },
        loaded: (instance) => {
          console.log(instance);
          const data = {
            id: instance.get_distinct_id(),
            country: instance.getFeatureFlag("country-es")
              ? Country.es
              : instance.getFeatureFlag("country-mn")
              ? Country.mn
              : Country.us,
            getFlag: (key: string) => instance.getFeatureFlag(key),
            paywallAB:
              (instance.getFeatureFlag("paywall-a-b") as boolean) || false,
          };
          setPaywallAB(data.paywallAB);
          setAnalytics(data);
        },

        debug: isDev,
      });
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return analytics;
};

export const logPageView = () => {
  if (isDev) return;
  try {
    posthog.capture("Page View");
  } catch (e) {}
};

export const identify = (
  uid: string,
  props: { displayName: string; email?: string; phone?: string; age?: number }
) => {
  if (isDev) return;
  try {
    posthog.identify(uid, props);
  } catch (e) {}
};

export const reset = () => {
  if (isDev) return;
  try {
    posthog.reset();
  } catch (e) {}
};

type Event = {
  name: string;
  category: string;
  label: string;
  value: number;
  interaction: boolean;
};

export const logEvent = ({
  name,
  category,
  label,
  value,
  interaction,
}: Event) => {
  if (isDev) return;
  try {
    posthog.capture(name, { category, label, value, interaction });
  } catch (e) {}
};

export const logCustomEvent = (event: string, values?: any) => {
  if (isDev) return;
  try {
    posthog.capture("Custom Event", { event, ...values });
  } catch (e) {}
};

const ErrorTypes = (code: number): string =>
  code === 400
    ? "Request Error"
    : code === 401
    ? "Unauthorized Error"
    : code === 404
    ? "Not Found Error"
    : code === 422
    ? "Validation Error"
    : code === 500
    ? "Internal Server Error"
    : code === 501
    ? "Not Implemented Error"
    : "Error";

export const logError = (error: any) => {
  if (isDev) return;
  try {
    posthog.capture(ErrorTypes(error?.statusCode), error);
  } catch (e) {}
};
