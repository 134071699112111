import { Country, User } from "@prisma/client";
import { AppError } from "@util/errors";
import { API, Method } from "@util/query";
import { useMutation, useQuery } from "react-query";

export type AuthUser = Pick<
  User,
  "id" | "email" | "phoneNumber" | "role" | "lastLoggedInAt" | "quiz"
> & {
  profile: any;
  hospital: any;
} & {
  hospitalUsers: {
    hospital: {
      id: string;
      country: Country;
      hospitalName: string;
      hospitalEng: string;
      availableCredit: number;
    };
  }[];
};

export const useCurrentUser = () => {
  const query = useQuery<AuthUser, AppError>("currentUser", API._current());
  return {
    isLoggedIn: query.data ? !!query.data.id : false,
    user: query.data && query.data.id ? query.data : null,
    ...query,
  };
};

export const useRefresh = () =>
  useQuery([`refresh`], API._query(Method.GET, `auth/refresh`));

export const useLogin = () =>
  useMutation(API._auth(Method.POST, `auth/login`, true));
export const useRequestOneTimeCode = () =>
  useMutation(API._auth(Method.PATCH, `auth/loginonetimecode`, true));

export const useLoginWithOneTimeCode = () =>
  useMutation(API._auth(Method.POST, `auth/loginonetimecode`, true));
//for admin login
export const useRequestOneTimeCodeAdmin = () =>
  useMutation(API._auth(Method.PATCH, `auth/loginonetimecode/admin`, true));
export const useLoginWithOneTimeCodeAdmin = () =>
  useMutation(API._auth(Method.POST, `auth/loginonetimecode/admin`, true));

export const useSignup = () =>
  useMutation(API._auth(Method.POST, `auth/signup`));
export const useSignupNourish = () =>
  useMutation(API._auth(Method.POST, `auth/signupnourish`));
export const usePasswordCreate = () =>
  useMutation(API._auth(Method.PUT, `auth/password/create`, true));
export const usePasswordForgotRequest = () =>
  useMutation(API._auth(Method.PATCH, `auth/password/forgot`));
export const usePasswordForgotCheck = () =>
  useMutation(API._auth(Method.POST, `auth/password/forgot`));

export const usePasswordForgotReset = () =>
  useMutation(API._auth(Method.PUT, `auth/password/forgot`, true));

export const usePhoneVerification = () =>
  useMutation(API._mutate(Method.POST, `auth/sendverification`));

export const usePhoneConfirmation = () =>
  useMutation(API._mutate(Method.POST, `auth/confirmverification`));

export const useLogout = () =>
  useMutation(API._mutate(Method.DELETE, `auth/logout`));

export const useDeleteAccount = () =>
  useMutation(API._mutate(Method.DELETE, `auth/delete`));
